import React, { useState } from "react";
import { ChromePicker } from "react-color";
import ColorizeIcon from "@material-ui/icons/Colorize";
function CustomPicker({ color, onChangeComplete }) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const popover = {
    position: "absolute",
    zIndex: "2",
  };

  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };

  return (
    <div
      style={{
        display: "inline-block",
        verticalAlign: "middle",
      }}
    >
      <ColorizeIcon style={{ cursor: "pointer" }} onClick={handleClick} />
      {displayColorPicker ? (
        <div style={popover} className="custompicker">
          <div style={cover} onClick={handleClose} />
          <ChromePicker color={color} onChangeComplete={onChangeComplete} />
        </div>
      ) : null}
    </div>
  );
}

export default CustomPicker;
