import React, { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {
  Checkbox,
  OutlinedInput,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

// const names = [
//   "Oliver Hansen",
//   "Van Henry",
//   "April Tucker",
//   "Ralph Hubbard",
//   "Omar Alexander",
//   "Carlos Abbott",
//   "Miriam Wagner",
//   "Bradley Wilkerson",
//   "Virginia Andrews",
//   "Kelly Snyder",
// ];
// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
const MenuProps = {
  getContentAnchorEl: () => null,
  PaperProps: {
    style: {
      // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxHeight: "400px",
      width: 350,
    },
  },
};
const WMSDialog = (props) => {
  const [textValWMS, setTextValWMS] = useState({ value: "", error: false });
  const [textValLayerName, setTextValLayerName] = useState({
    value: "",
    error: false,
  });
  const [payLoad, setPayload] = useState(null);
  // const [wmsLayerName, setWmsLayerName] = useState(null);
  const [wmsLayerNameError, setWmsLayerNameError] = useState(false);
  const [wmsLayerList, setWmsLayerList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  //Example Code
  const [layerList, setLayerList] = useState(["Layer"]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setLayerList(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const wmsUrlSplitter = (val, serviceType, layerData) => {
    const rawUrl = val;

    let wmsUrl = rawUrl.substr(0, rawUrl.indexOf("?")) + "?";

    let checkGeoServerWms = false;
    let checkArcGisServer = false;
    let checkOgcWms = false;
    // let checkOtherService = false;
    // let checkWmts = false;
    // let layerName = null;
    // let check;
    checkGeoServerWms = rawUrl.includes("geoserver");
    checkOgcWms = rawUrl.includes("ogc");

    var str = rawUrl;
    var arr = ["arcgis", "mapserver", "MapServer", "ArcGIS"];

    function contains(target, pattern) {
      var value = 0;
      pattern.forEach(function(word) {
        value = value + target.includes(word);
      });
      return value === 1;
    }

    checkArcGisServer = contains(str, arr);
    let layerType = "";
    if (checkGeoServerWms) {
      // let layerNameRaw = rawUrl.substring(
      //   rawUrl.indexOf("geoserver/"),
      //   rawUrl.lastIndexOf("?")
      // );

      // let layerNameSplit = layerNameRaw.split("/");
      // layerName = layerNameSplit[layerNameSplit.length - 2];

      // setWmsLayerName(layerName);
      layerType = "checkGeoServerWms";
    } else if (checkArcGisServer) {
      // check = contains(str, ["layer="]);
      // if (check) {
      //   layerName = rawUrl.split("layer=")[1];
      //   setWmsLayerName(layerName);
      // }
      layerType = "checkArcGisServer";
    } else if (checkOgcWms) {
      // check = contains(str, ["LAYERS="]);
      // if (check) {
      //   let layerNameRaw = rawUrl.substring(
      //     rawUrl.indexOf("LAYERS="),
      //     rawUrl.lastIndexOf("&")
      //   );

      //   console.log("layerNameRaw", layerNameRaw);
      //   let layerNameSplit = layerNameRaw.split("&");
      //   let layerNameSplit1 = layerNameSplit[0].split("=");
      //   layerName = layerNameSplit1[1];

      //   setWmsLayerName(layerName);
      layerType = "checkOgcWms";
    } else if (serviceType == "WMTS") {
      layerType = "checkWmts";
      wmsUrl = rawUrl;
    } else {
      layerType = "checkOtherService";
    }

    if (wmsUrl) {
      const objPayLoad = {
        textValWMS: wmsUrl,
        wmsLayerType: layerType,
        layerData: layerData ? layerData : null,
      };
      setPayload(objPayLoad);
    }
  };

  function identifyServiceTypeByUrl(url) {
    // Check for 'wms' or 'wmts' (case insensitive) in the URL
    const lowerCaseUrl = url.toLowerCase();

    if (lowerCaseUrl.includes("wmts")) {
      console.log("Service Type identified by URL: WMTS");
      return "WMTS";
    } else if (lowerCaseUrl.includes("wms")) {
      console.log("Service Type identified by URL: WMS");
      return "WMS";
    } else {
      console.log(
        "Service Type could not be determined from the URL alone. Fetching XML..."
      );
      return null;
    }
  }

  const getWmsUrlLayerList = (url) => {
    const serviceType = identifyServiceTypeByUrl(url);
    console.log("FinalServiceType:", serviceType);
    setIsLoading(true);
    const rawUrl = url;
    const subStrUrl = rawUrl.substr(0, rawUrl.indexOf("?"));
    var xhr = new XMLHttpRequest();
    if (serviceType == "WMS") {
      xhr.open("GET", subStrUrl + "?service=WMS&request=GetCapabilities", true);
      xhr.onreadystatechange = function() {
        if (xhr.readyState === 4 && xhr.status === 200 && subStrUrl) {
          setIsLoading(false);
          // Parse XML response
          var xmlDoc = xhr.responseXML;
          var layerNodes = xmlDoc.getElementsByTagName("Layer");
          var layerNames = [];
          var layerName = null;
          if (serviceType == "WMS") {
            for (var i = 0; i < layerNodes.length; i++) {
              layerName = layerNodes[i].getElementsByTagName("Name")[0]
                .textContent;
              layerNames.push(layerName);
            }
          }

          setWmsLayerList(layerNames);
          setLayerList([layerNames[0]]);
          wmsUrlSplitter(textValWMS.value, serviceType, false);
          // Output layer names
          console.log("Available Layers:", layerNames, "Success");
        } else {
          console.log("Available Layers:", layerNames, "Failed");
          setWmsLayerList([]);
          // setWmsLayerName(null);
        }
      };
      xhr.send();
    } else if (serviceType == "WMTS") {
      fetch(rawUrl)
        .then((response) => response.text())
        .then((xmlString) => {
          setIsLoading(false);
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(xmlString, "application/xml");

          const layers = xmlDoc.getElementsByTagName("Layer");

          const layerData = Array.from(layers)
            .map((layer) => {
              // Get Identifier
              const identifier = layer.getElementsByTagNameNS(
                "http://www.opengis.net/ows/1.1",
                "Identifier"
              )[0].textContent;

              // Get Resource URL
              const resourceURL = layer
                .getElementsByTagName("ResourceURL")[0]
                .getAttribute("template");

              // Get Bounding Box
              const lowerCorner = layer
                .getElementsByTagNameNS(
                  "http://www.opengis.net/ows/1.1",
                  "LowerCorner"
                )[0]
                .textContent.split(" ");
              const upperCorner = layer
                .getElementsByTagNameNS(
                  "http://www.opengis.net/ows/1.1",
                  "UpperCorner"
                )[0]
                .textContent.split(" ");
              const tileMatrixSetId = layer
                .getElementsByTagName("TileMatrixSetLink")[0]
                .getElementsByTagName("TileMatrixSet")[0].textContent;

              const boundingBox = `${lowerCorner[0]}, ${lowerCorner[1]}, ${upperCorner[0]}, ${upperCorner[1]}`;

              return { identifier, resourceURL, boundingBox, tileMatrixSetId };
            })
            .filter(
              (layer) => isNaN(layer.identifier) && layer.identifier.length > 3
            );
          var layerNames = [];
          var layerName = null;
          for (var i = 0; i < layerData.length; i++) {
            layerName = layerData[i].identifier;

            layerNames.push(layerName);
          }
          setWmsLayerList(layerNames);
          setLayerList([layerNames[0]]);
          wmsUrlSplitter(textValWMS.value, serviceType, layerData);
          console.log("identifierList", layerData);
        })
        .catch((error) => console.error("Error fetching the XML:", error));
    }
  };

  const handleSubmit = () => {
    // let person=payLoad.layerData[0]
    const layerData = payLoad.layerData;
    let filterObjects = [];
    let stringify = "";
    if (layerData) {
      filterObjects = layerData.filter(
        (obj) => obj.identifier && layerList.includes(obj.identifier)
      );
      stringify = JSON.stringify(filterObjects);
    }
    console.log("stringify", stringify);
    const obj = {
      textValWMS: payLoad?.textValWMS,
      wmsLayerType: payLoad?.wmsLayerType,
      layerLabel: textValLayerName.value,
      layerData: payLoad.layerData ? stringify : "",
      layerNamesArray:
        layerList.length > 0 && layerList[0] != "Layer" ? layerList : null,
    };
    console.log("objPayLoad", obj, "layerList", layerList);
    if (
      obj.textValWMS &&
      obj.layerLabel &&
      layerList.length > 0 &&
      layerList[0] != "Layer"
    ) {
      props.uploadWmsLayerFn(obj);
      setTextValLayerName({ value: "", error: false });
      setTextValWMS({ value: "", error: false });
      setWmsLayerNameError(false);
      // setWmsLayerName(null);
      setWmsLayerList([]);
      setIsLoading(false);
      setLayerList(["Layer"]);
    } else {
      textValWMS.value === ""
        ? setTextValWMS({
            ...textValWMS,
            error: true,
          })
        : setTextValWMS({
            ...textValWMS,
            error: false,
          });

      textValLayerName.value === ""
        ? setTextValLayerName({ ...textValLayerName, error: true })
        : setTextValLayerName({ ...textValLayerName, error: false });
      layerList.length === 0 || layerList[0] === "Layer"
        ? setWmsLayerNameError(true)
        : setWmsLayerNameError(false);
    }
  };

  const handleDeleteItem = (index) => {
    const updatedItems = [...layerList];
    updatedItems.splice(index, 1);

    setLayerList(updatedItems);
  };
  return (
    <>
      <Dialog
        TransitionComponent={props.transition}
        open={props.addWMSDialog}
        maxWidth={"sm"}
        fullWidth={true}
        onClose={() => {
          props.setAddWMSDialog(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Add WMS/WMTS Layer
          <IconButton
            style={{ float: "right" }}
            aria-label="close"
            //  className={classes.closeButton}
            onClick={() => {
              props.setAddWMSDialog(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1 },
            }}
          >
            <Grid container spacing={1} columnSpacing={{ xs: 2 }}>
              <Grid item xs={12}>
                <TextField
                  error={textValWMS.error}
                  style={{ fontSize: 15 }}
                  id="wms"
                  label="Add WMS/WMTS URL"
                  fullWidth
                  variant="standard"
                  value={textValWMS.value}
                  onChange={(event) => {
                    setTextValWMS({ ...textValWMS, value: event.target.value });
                  }}
                  onBlur={(event) => {
                    if (textValWMS.value != "") {
                      getWmsUrlLayerList(textValWMS.value);
                    } else {
                      setLayerList(["Layer"]);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  error={textValLayerName.error}
                  style={{ fontSize: 15 }}
                  id="layerwms"
                  autoFocus
                  label="Add Layer Name Prefix"
                  fullWidth
                  variant="standard"
                  value={textValLayerName.value}
                  onChange={(event) => {
                    setTextValLayerName({
                      ...textValLayerName,
                      value: event.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Select
                  multiple
                  error={wmsLayerNameError}
                  disabled={wmsLayerList.length > 0 ? false : true}
                  style={{ fontSize: 15, marginTop: "17px" }}
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  value={isLoading ? ["Loading..."] : layerList}
                  onChange={handleChange}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  fullWidth
                >
                  {wmsLayerList.map((k, i) => {
                    return (
                      <MenuItem value={k} key={"keyWmsLayerList" + i + k}>
                        {/* {k} */}
                        <Checkbox checked={layerList.indexOf(k) > -1} />
                        <ListItemText primary={k} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              {layerList.length > 0 && layerList[0] != "Layer" && (
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    component="div"
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    Selected Layers
                  </Typography>
                  <div
                    style={{
                      maxHeight: "200px", // Set a maximum height for the scrollable div
                      overflowY: "auto", // Add vertical scroll if needed
                    }}
                  >
                    <List
                    // style={{
                    //   width: "50%",
                    //   maxWidth: 160,
                    // }}
                    >
                      {layerList.map((value, index) => {
                        const labelId = `checkbox-list-label-${value}`;

                        return (
                          <Paper
                            style={{
                              maxHeight: 50,
                              margin: "5px",
                              boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            <ListItem
                              style={{ fontSize: "8px" }}
                              disablePadding
                            >
                              {/* <ListItemIcon></ListItemIcon> */}
                              <ListItemText
                                id={labelId}
                                // primary={`Line item ${value + 1}`}
                              >
                                <span
                                  style={{ fontSize: "0.92rem" }}
                                >{`${value}`}</span>
                              </ListItemText>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => handleDeleteItem(index)}
                              >
                                <CloseIcon style={{ fontSize: "16px" }} />
                              </IconButton>
                            </ListItem>
                          </Paper>
                        );
                      })}
                    </List>
                  </div>
                </Grid>
              )}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setTextValLayerName({ value: "", error: false });
              setTextValWMS({ value: "", error: false });
              props.setAddWMSDialog(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleSubmit} type="submit" color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WMSDialog;
