import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import OrganizationProjects from "../../../OrganizationProjects";
import ShareBox from "./ShareBox";
import Avatar from "@material-ui/core/Avatar";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { blue } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import secureLocalStorage from "react-secure-storage";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import PersonIcon from "@material-ui/icons/Person";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MaterialTable from "material-table";
import React, { forwardRef, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getProjectsShare,
  getProjectsUsers,
  updateProjectPermissions,
} from "../../../../../services/user.service";
import { LOADING_MESSAGE, SET_MESSAGE } from "./../../../../../actions/types";
import { allowedUser } from "./../../../../common/common";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SharingTabs(props) {
  useEffect(() => {
    dispatch({ type: LOADING_MESSAGE, isLoading: true });
    getProjectsShare(props.id)
      .then((res) => {
        setTokens(res.data.shareTokens);
        dispatch({ type: LOADING_MESSAGE, isLoading: false });
      })
      .catch((error) => {
        dispatch({ type: LOADING_MESSAGE, isLoading: false });
        // props.errorDisplay(
        //   "error",
        //   error.response ? error.response.data.message : "Request Unsuccessful"
        // );
      });
    getProjectsUsers(
      JSON.parse(secureLocalStorage.getItem("organizationID")),
      props.id
    )
      .then((res) => {
        dispatch({ type: LOADING_MESSAGE, isLoading: false });
        setCurrentProject(props.id);
        setUsers(res.data.data.projectUsers);
        setEntriesLength(true);
        setDeleteDrawer(true);
        setEdittedUsers([]);
      })
      .catch((error) => {
        dispatch({ type: LOADING_MESSAGE, isLoading: false });
        // props.errorDisplay(
        //   "error",
        //   error.response ? error.response.data.message : "Request Unsuccessful"
        // );
      });
  }, []);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const { isOrganizationAdmin } = useSelector((state) => state.auth);
  const { message, isLoading } = useSelector(
    (state) => state.message,
    shallowEqual
  );
  const [deleteDrawer, setDeleteDrawer] = useState(false);
  const [entriesLength, setEntriesLength] = useState(true);
  const [users, setUsers] = useState([]);
  const [tokens, setTokens] = useState(false);
  const [currentProject, setCurrentProject] = useState("");
  const [edittedUsers, setEdittedUsers] = useState([]);
  const [data, setData] = useState([]);
  const typeID = JSON.parse(secureLocalStorage.getItem("typeID"));
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleCloseDeleteDialog = () => {
    setEntriesLength(true);
    setDeleteDrawer(false);
  };

  return (
    <div className={classes.root}>
      {secureLocalStorage.getItem("isOrganizationAdmin") ||
      allowedUser.includes(typeID) ? (
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="Share Link (Read Only)" {...a11yProps(0)} />
            <Tab label="Share With Team (Permissions)" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
      ) : null}
      <TabPanel className="sharingPanel usersTable" value={value} index={0}>
        {tokens && (
          <ShareBox
            name={props.name}
            id={props.id}
            tokens={tokens}
            setTokens={(newData) => setTokens(newData)}
          />
        )}
      </TabPanel>
      <TabPanel
        value={value}
        index={1}
        className="sharingPermission sharingPanel"
      >
        <Backdrop
          open={isLoading}
          style={{ zIndex: 99999999999, color: "#fff" }}
        >
          <CircularProgress />
        </Backdrop>
        <List
          style={{ height: 345, overflow: "auto", width: 750, margin: "auto" }}
        >
          {users.map((user) => {
            return (
              <ListItem
                button
                //   onClick={() => handleListItemClick(user.email)}
                key={user.email}
              >
                <ListItemAvatar>
                  <Avatar className={classes.avatar}>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  style={{ marginRight: 10 }}
                  primary={user.email}
                />
                <Autocomplete
                disabled={secureLocalStorage.getItem("email") == user.email}
                  onChange={(a, b, c) => {
                    // let entries= edittedUsers;
                    const index = edittedUsers.findIndex(
                      (e) => e.userID === user.id
                    );
                    if (index === -1) {
                      edittedUsers.push({
                        view: b.includes("View") || b.includes("All"),
                        edit: b.includes("Edit") || b.includes("All"),
                        delete: b.includes("Delete") || b.includes("All"),
                        download: b.includes("Download") || b.includes("All"),
                        userID: user.id,
                      });
                    } else {
                      edittedUsers[index] = {
                        view: b.includes("View") || b.includes("All"),
                        edit: b.includes("Edit") || b.includes("All"),
                        delete: b.includes("Delete") || b.includes("All"),
                        download: b.includes("Download") || b.includes("All"),
                        userID: user.id,
                      };
                    }
                    if (edittedUsers.length) {
                      setEntriesLength(false);
                    } else {
                      setEntriesLength(true);
                    }
                    if (a.target.innerHTML.includes("All")) {
                      let temp = users.map((item) => {
                        if (item.id == user.id) {
                          item.projectPermissions[0].download = true;
                          item.projectPermissions[0].delete = true;
                          item.projectPermissions[0].edit = true;
                          item.projectPermissions[0].view = true;
                        }
                        return item;
                      });
                      setUsers(users.filter((item) => item.id != user.id));
                      setTimeout(() => {
                        setUsers(temp);
                      }, 1);
                    }
                    // currentProject
                    // edittedUsers
                    // ;
                    // setEdittedUsers(entries)

                    // if (b.length < 6) {
                    //   // setInfoLayerTags(b);
                    // } else {
                    //   // setMessageAlertType("error");
                    //   // setMessageAlert("Maximum 5 Tags can be added");
                    //   // handleClickAlert();
                    // }
                  }}
                  size="small"
                  style={{ width: 350 }}
                  multiple
                  id="tags-outlined"
                  options={["All", "View", "Edit", "Delete", "Download"]}
                  getOptionLabel={(option) => option}
                  defaultValue={() => {
                    if (user.projectPermissions.length) {
                      let selections = [];
                      if (user.projectPermissions[0].view) {
                        selections.push("View");
                      }
                      if (user.projectPermissions[0].download) {
                        selections.push("Download");
                      }
                      if (user.projectPermissions[0].delete) {
                        selections.push("Delete");
                      }
                      if (user.projectPermissions[0].edit) {
                        selections.push("Edit");
                      }
                      return selections;
                    } else {
                      return [];
                    }
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Permissions"
                      // placeholder="Permissions"
                    />
                  )}
                />
              </ListItem>
            );
          })}

          {/* <ListItem
            autoFocus
            button
            // onClick={() => handleListItemClick('addAccount')}
          >
            <ListItemAvatar>
              <Avatar>
                <AddIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Add account" />
          </ListItem> */}
        </List>
        <Button
          onClick={() => {
            dispatch({ type: LOADING_MESSAGE, isLoading: true });
            updateProjectPermissions(
              JSON.parse(secureLocalStorage.getItem("organizationID")),
              currentProject,
              edittedUsers
            )
              .then(() => {
                dispatch({ type: LOADING_MESSAGE, isLoading: false });
                handleCloseDeleteDialog();
              })
              .catch((error) => {
                dispatch({ type: LOADING_MESSAGE, isLoading: false });
                props.errorDisplay(
                  "error",
                  error.response.data.message
                    ? error.response.data.message
                    : "Request Unsuccessful"
                );
              });
          }}
          style={{ marginTop: 12 }}
          variant="contained"
          className="btn-block btnTheme"
          disabled={entriesLength}
          autoFocus
        >
          Save
        </Button>
      </TabPanel>
    </div>
  );
}
