const mapConfig = {
  infoPanel: {
    heightColorList: [
      {
        id: 0,
        colorPalletName: "Viridis",
        color1: "#F6E41B",
        color2: "#49BE53",
        color3: "#2E3C78",
        color4: "#340045",
      },
      {
        id: 1,
        colorPalletName: "Magma",
        color1: "#FBFAAD",
        color2: "#A72066",
        color3: "#A72066",
        color4: "#060411",
      },

      {
        id: 2,
        colorPalletName: "Plasma",
        color1: "#EEF71E",
        color2: "#BE3065",
        color3: "#660097",
        color4: "#120079",
      },
      {
        id: 3,
        colorPalletName: "Inferno",
        color1: "#FCFF93",
        color2: "#D73F29",
        color3: "#3F0059",
        color4: "#060412",
      },
      {
        id: 4,
        colorPalletName: "Cividis",
        color1: "#FFE635",
        color2: "#6D6A66",
        color3: "#384058",
        color4: "#011C4E",
      },
    ],
    defaultStyleArray: [
      {
        id: 0,
        classId: 0,
        className: "Not Classified",
        color: "#848484",
        active: true,
      },
      {
        id: 1,
        classId: 2,
        className: "Ground",
        color: "#FFA343",
        active: true,
      },
      {
        id: 2,
        classId: 3,
        className: "Low Vegetation",
        color: "#76FF7A",
        active: true,
      },
      {
        id: 3,
        classId: 4,
        className: "Medium Vegetation",
        color: "#B2EC5D",
        active: true,
      },
      {
        id: 4,
        classId: 5,
        className: "High Vegetation",
        color: "#1DF914",
        active: true,
      },
      {
        id: 5,
        classId: 6,
        className: "Building",
        color: "#CB4154",
        active: true,
      },
      {
        id: 6,
        classId: 9,
        className: "Water",
        color: "#1A4876",
        active: true,
      },
      {
        id: 7,
        classId: 11,
        className: "Road Surface",
        color: "#414A4C",
        active: true,
      },
      {
        id: 8,
        classId: 1,
        className: "Unassigned",
        color: "#808080",
        active: true,
      },
      {
        id: 9,
        classId: 7,
        className: "Low Point",
        color: "#808080",
        active: true,
      },
      {
        id: 10,
        classId: 8,
        className: "Reserved 1",
        color: "#808080",
        active: true,
      },
      {
        id: 11,
        classId: 10,
        className: "Rail",
        color: "#808080",
        active: true,
      },
      {
        id: 12,
        classId: 12,
        className: "Reserved 2",
        color: "#808080",
        active: true,
      },
      {
        id: 13,
        classId: 13,
        className: "Wire - Guard (Shield)",
        color: "#808080",
        active: true,
      },
      {
        id: 14,
        classId: 14,
        className: "Wire - Conductor (Phase)",
        color: "#808080",
        active: true,
      },
      {
        id: 15,
        classId: 15,
        className: "Transmission Tower",
        color: "#808080",
        active: true,
      },
      {
        id: 16,
        classId: 16,
        className: "Wire-Structure Connector (Insulator)",
        color: "#808080",
        active: true,
      },
      {
        id: 17,
        classId: 17,
        className: "Bridge Deck",
        color: "#808080",
        active: true,
      },
      {
        id: 18,
        classId: 18,
        className: "High Noise",
        color: "#808080",
        active: true,
      },
    ],
  },
};

export default mapConfig;
